<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ $t("AUTH.LOGIN.TITLE") }}</h3>
      </div>

      <!--begin::Form-->
      <b-form
        v-if="!showTwoFactorForm"
        class="form"
        @submit.stop.prevent="onSubmit"
      >
        <div role="alert" class="alert alert-dark">
          <div class="alert-text">
            {{ $t("AUTH.LOGIN.DESCRIPTION") }}
          </div>
        </div>

        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div
            class="alert-text"
            v-for="(error, i) in errors"
            :key="'error_' + i"
          >
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
        <b-form-group
          class="pb-3"
          id="email-input-group"
          label=""
          label-for="email-input"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="email-input"
            name="email-input"
            type="email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="email-input-live-feedback"
            v-bind:placeholder="$t('AUTH.INPUT.EMAIL')"
          ></b-form-input>

          <b-form-invalid-feedback id="email-input-live-feedback">
            {{ $t("AUTH.LOGIN.VALID_EMAIL") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="password-input-group"
          label=""
          label-for="password-input"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="password-input"
            name="password-input"
            v-bind:placeholder="$t('AUTH.INPUT.PASSWORD')"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="password-input-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="password-input-live-feedback">
            {{ $t("AUTH.LOGIN.VALID_PASSWORD") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            href="#"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <!-- {{ $t("AUTH.FORGOT.TITLE") }} -->
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-dark font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            {{ $t("AUTH.LOGIN.BUTTON") }}
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
      <TwoFactorForm
        v-if="showTwoFactorForm"
        :email="form.email"
        :password="form.password"
        @click:back="showTwoFactorForm = false"
      />
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { LOGIN, TWOFACTOR } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import TwoFactorForm from "./components/TwoFactorForm.vue";

const IS_TWO_FACTOR = true;

export default {
  mixins: [validationMixin],
  components: { TwoFactorForm },
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      showTwoFactorForm: false
    };
  },
  mounted() {
    // check if current user is authenticated
    if (this.isAuthenticated) {
      this.$router.push({ name: "dashboard" });
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(4)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      // send login request
      this.$store
        .dispatch(IS_TWO_FACTOR ? TWOFACTOR : LOGIN, {
          email: email,
          password: password
        })
        // go to which page after successfully login
        .then(() => {
          IS_TWO_FACTOR
            ? (this.showTwoFactorForm = true)
            : this.$router.push({ name: "dashboard" });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
